import React from 'react'
import { bs } from '../shevy'

const baseButtonStyles = {
  border: 'none',
  borderRadius: '2px',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: 'var(--fonts-catamaran)',
  fontSize: '0.85rem',
  lineHeight: 1,
  padding: `${bs(0.25)} ${bs(0.5)} ${bs(0.35)}`,
  textAlign: 'center',
  transition: 'background-color 0.3s ease',

  '&:disabled': {
    cursor: 'default',
  },
}

export const defaultButtonStyles = {
  ...baseButtonStyles,
  backgroundColor: 'var(--components-button-background)',
  color: 'var(--components-button-text)',

  '&:disabled': {
    backgroundColor: 'var(--colors-offsetMore)',

    '&:hover': {
      backgroundColor: 'var(--colors-offsetMore)',
    },
  },

  '&:hover': {
    backgroundColor: 'var(--components-button-hover-background)',
    color: 'var(--components-button-hover-text)',
  },

  '&:active': {
    backgroundColor: 'var(--components-button-active-background)',
    color: 'var(--components-button-active-text)',
  },
}

const secondaryButtonStyles = {
  ...baseButtonStyles,
  backgroundColor: 'var(--colors-offset)',
  color: 'var(--colors-text)',

  '&:disabled': {
    backgroundColor: 'var(--colors-offsetMore)',
  },
}

const linkButtonStyles = {
  appearance: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  color: 'var(--colors-accent)',
  fontFamily: 'var(--fonts-catamaran)',
  padding: 0,
  transition: 'color .3s ease',

  '&:hover': {
    color: 'var(--colors-accentLight)',
  },
}

const BUTTON_STYLES = {
  default: defaultButtonStyles,
  secondary: secondaryButtonStyles,
  link: linkButtonStyles,
}

export default function Button({
  children,
  disabled = false,
  onClick,
  styleOverrides = {},
  type = 'button',
  variant = 'default',
  ...rest
}) {
  const styles = BUTTON_STYLES[variant]

  return (
    <button
      css={{ ...styles, ...styleOverrides }}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}
