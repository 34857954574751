import React from 'react'
import generateContext from 'react-generate-context'
import { useAuthContext } from './AuthContext'
import { useSupabaseContext } from './SupabaseContext'

const usePurchaseValue = () => {
  const supabase = useSupabaseContext()
  const [, { session }] = useAuthContext()
  const [purchases, setPurchases] = React.useState([])
  const [state, setState] = React.useState('IDLE')

  React.useEffect(() => {
    if (!session || !supabase) return

    async function getData() {
      try {
        const { data, error } = await supabase.from('purchases').select()

        if (error) {
          console.log(error)
          setState('FAILURE')
          return
        }

        if (data) {
          setState('SUCCESS')
          setPurchases(data)
          return
        }
      } catch (error) {
        console.log(error)
        setState('FAILURE')
      }
    }

    getData(session?.user.id)
  }, [session, supabase])

  return [state, purchases]
}

const [PurchaseProvider, usePurchaseContext] = generateContext(usePurchaseValue)
PurchaseProvider.displayName = 'PurchaseProvider'

export { PurchaseProvider, usePurchaseContext }
