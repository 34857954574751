import React from 'react'
import generateContext from 'react-generate-context'
import { useSupabaseContext } from './SupabaseContext'

const useAuthValue = () => {
  const supabase = useSupabaseContext()
  const [state, setState] = React.useState('NOT_AUTHENTICATED')
  const [user, setUser] = React.useState(null)
  const [storedSession, setStoredSession] = React.useState(null)
  const api = React.useMemo(
    () => ({
      signUp: async (email, password) => {
        if (!supabase) return

        const { user, session, error } = await supabase.auth.signUp({
          email,
          password,
        })

        return { user, session, error }
      },
      login: async (email, password) => {
        if (!supabase) return

        const { user, session, error } = await supabase.auth.signIn({
          email,
          password,
        })

        return { user, session, error }
      },
      logout: async () => {
        if (!supabase) return

        const { error } = await supabase.auth.signOut()

        return { error }
      },
      getCurrentUser: () => {
        if (!supabase) return

        return supabase.auth.user()
      },
      sendResetPasswordEmail: async email => {
        if (!supabase) return

        const { data, error } = await supabase.auth.api.resetPasswordForEmail(
          email
        )

        return { data, error }
      },
      updatePasswordViaToken: async (accessToken, newPassword) => {
        if (!supabase) return

        const { data, error } = await supabase.auth.api.updateUser(
          accessToken,
          {
            password: newPassword,
          }
        )

        return { data, error }
      },
    }),
    [supabase]
  )

  useDetectExistingSession({ setState, setStoredSession, setUser, supabase })
  useSubscribeToAuthChanges({ setState, setStoredSession, setUser, supabase })

  return [
    state,
    {
      ...api,
      session: storedSession,
      user,
    },
  ]
}

const [AuthProvider, useAuthContext] = generateContext(useAuthValue)

export { AuthProvider, useAuthContext }

/**
 * Supabase stores the session in localStorage, thus we need to
 * check if a session exists so that we know the user is logged in
 */
function useDetectExistingSession({
  setState,
  setStoredSession,
  setUser,
  supabase,
}) {
  React.useEffect(() => {
    if (!supabase) return

    const session = supabase.auth.session()

    if (session) {
      setState('AUTHENTICATED')
      setStoredSession(session)
      setUser(session.user)
    }
  }, [setState, setStoredSession, setUser, supabase])
}

/**
 * Supabase provides an onAuthStateChange listener that we can use
 * to maintain the state of our session and user
 */
function useSubscribeToAuthChanges({
  setState,
  setStoredSession,
  setUser,
  supabase,
}) {
  React.useEffect(() => {
    if (!supabase) return

    const { data: listener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setStoredSession(session)

        if (event === 'SIGNED_IN') {
          setState('AUTHENTICATED')
          setUser(session?.user ?? null)
        }

        if (event === 'SIGNED_OUT') {
          setState('NOT_AUTHENTICATED')
          setUser(null)
        }

        return () => listener?.unsubscribe()
      }
    )
  }, [setState, setStoredSession, setUser, supabase])
}
