import React from 'react'

const LIGHT_COLORS = {
  background: 'hsl(0, 0%, 100%)',
  text: 'hsl(0, 0%, 10%)',
  accent: 'hsl(197, 60%, 50%)',
  accentDark: 'hsl(197, 60%, 40%)',
  accentLight: 'hsl(197, 60%, 60%)',
  accentExtraLight: 'hsl(197, 50%, 90%)',
  contra: 'hsl(347, 87%, 60%)',
  contraLight: 'hsl(347, 87%, 65%)',
  offset: 'hsl(190, 23%, 95%)',
  offsetMore: 'hsl(197, 12%, 65%)',
  error: 'hsl(347, 71%, 54%)',
}

const DARK_COLORS = {
  background: 'hsl(195, 60%, 4%)',
  text: 'hsl(0, 0%, 100%)',
  accent: 'hsl(197, 60%, 50%)',
  accentDark: 'hsl(197, 60%, 40%)',
  accentLight: 'hsl(197, 60%, 60%)',
  contra: 'hsl(347, 87%, 60%)',
  contraLight: 'hsl(347, 87%, 65%)',
  offset: 'hsl(197, 61%, 14%)',
  offsetMore: 'hsl(197, 61%, 24%)',
  error: 'hsl(347, 71%, 54%)',
}

const makeDefaultCSSVars = colors => ({
  '--colors-background': colors.background,
  '--colors-text': colors.text,
  '--colors-accent': colors.accent,
  '--colors-accentDark': colors.accentDark,
  '--colors-accentLight': colors.accentLight,
  '--colors-accentExtraLight': colors.accentExtraLight,
  '--colors-contra': colors.contra,
  '--colors-contraLight': colors.contraLight,
  '--colors-offset': colors.offset,
  '--colors-offsetMore': colors.offsetMore,
  '--colors-error': colors.error,
  '--colors-text-on-accent': 'var(--colors-background)',
  '--fonts-catamaran': 'Catamaran, sans-serif',
  '--fonts-droid': '"Droid Serif", serif',
  '--components-avatar-background': 'var(--colors-offsetMore)',
  '--components-avatar-text': 'var(--colors-background)',
  '--components-button-background': 'var(--colors-accent)',
  '--components-button-text': 'var(--colors-background)',
  '--components-button-hover-background': 'var(--colors-accentLight)',
  '--components-button-hover-text': 'var(--colors-background)',
  '--components-button-active-background': 'var(--colors-background)',
  '--components-button-active-text': 'var(--colors-text)',
  '--components-footer-background': 'var(--colors-offset)',
  '--components-footer-text': 'var(--colors-text)',
  '--components-header-background': 'var(--colors-text)',
  '--components-header-text': 'var(--colors-background)',
  '--components-inputs-background': 'var(--colors-background)',
  '--components-inputs-text': 'var(--colors-text)',
  '--components-nav-background': 'var(--colors-offset)',
  '--tags-code': 'var(--colors-offset)',
})

const LIGHT_VARS = {
  ...makeDefaultCSSVars(LIGHT_COLORS),
}

const DARK_VARS = {
  ...makeDefaultCSSVars(DARK_COLORS),
  '--colors-text-on-accent': 'var(--colors-text)',
  '--components-avatar-background': 'var(--colors-background)',
  '--components-avatar-text': 'var(--colors-text)',
  '--components-button-text': 'var(--colors-text)',
  '--components-button-hover-text': 'var(--colors-text)',
  '--components-header-background': 'var(--colors-offsetMore)',
  '--components-header-text': 'var(--colors-text)',
}

const makeCSSVarsString = obj =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    return acc + `${key}:${value};`
  }, '')

const THEME_TO_CSS_VARS = {
  dark: DARK_VARS,
  light: LIGHT_VARS,
}

const getCSSVars = (theme = 'light') =>
  makeCSSVarsString(THEME_TO_CSS_VARS[theme])

const DEFAULT_THEME = 'light'
const THEME_STORAGE_KEY = 'kyleshevlin:theme'
const NEXT_THEME = {
  dark: 'light',
  light: 'dark',
}

const getInitialTheme = () => {
  try {
    const themeName = localStorage.getItem(THEME_STORAGE_KEY)

    if (!themeName) return DEFAULT_THEME

    if (Object.keys(NEXT_THEME).includes(themeName)) {
      return themeName
    }

    return DEFAULT_THEME
  } catch (err) {
    return DEFAULT_THEME
  }
}

const ThemeContext = React.createContext()

export function ThemeProvider({ children }) {
  const [theme, setTheme] = React.useState(getInitialTheme)

  const rotateTheme = React.useCallback(() => {
    setTheme(state => NEXT_THEME[state])
  }, [])

  React.useEffect(() => {
    const html = document.documentElement
    html.setAttribute('style', getCSSVars(theme))
    html.setAttribute('class', theme)
    localStorage.setItem(THEME_STORAGE_KEY, theme)
  }, [theme])

  const value = React.useMemo(
    () => ({
      rotateTheme,
      theme,
    }),
    [rotateTheme, theme]
  )

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useTheme = () => React.useContext(ThemeContext)
