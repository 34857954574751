/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import CustomLayout from './src/wrapPageElement'
import CustomRootElement from './src/wrapRootElement'
import 'prism-theme-night-owl/build/no-italics.css'
import './src/styles/fontFaces.css'

export const wrapPageElement = CustomLayout
export const wrapRootElement = CustomRootElement
