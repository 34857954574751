import React from 'react'

export default function Vimeo({ src, title }) {
  return (
    <>
      <div
        css={{
          backgroundColor: 'var(--colors-offset)',
          paddingTop: '56.25%',
          position: 'relative',
        }}
      >
        <iframe
          src={src}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          title={title}
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  )
}
