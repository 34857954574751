import React from 'react'

export default function Moon({ width = 18 }) {
  return (
    <svg width={width} height={width} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.6505 1.02606L9.73071 0.0292841L9.12826 1.87886L9.6505 1.02606ZM1.88688 12.6647L2.47509 11.856L0.998306 13.1235L1.88688 12.6647ZM12 7C12 10.3137 9.31371 13 6 13V15C10.4183 15 14 11.4183 14 7H12ZM9.12826 1.87886C10.8529 2.93499 12 4.83398 12 7H14C14 4.10928 12.4662 1.5777 10.1727 0.173261L9.12826 1.87886ZM9.57029 2.02284C13.169 2.31243 16 5.32611 16 9H18C18 4.27532 14.36 0.401806 9.73071 0.0292841L9.57029 2.02284ZM16 9C16 12.866 12.866 16 9 16V18C13.9706 18 18 13.9706 18 9H16ZM9 16C6.29111 16 3.93987 14.4615 2.77546 12.206L0.998306 13.1235C2.49246 16.0177 5.51389 18 9 18V16ZM6 13C4.68128 13 3.4646 12.5757 2.47509 11.856L1.29867 13.4734C2.61854 14.4334 4.24458 15 6 15V13Z"
        fill="currentColor"
      />
    </svg>
  )
}
