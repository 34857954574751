import React from 'react'
import { Link } from 'gatsby'
import { defaultButtonStyles } from './Button'

const EXTERNAL_LINK_PATTERN = /^http/

export default function LinkButton({ children, href, styleOverrides = {} }) {
  if (EXTERNAL_LINK_PATTERN.test(href)) {
    return (
      <a css={{ ...defaultButtonStyles, ...styleOverrides }} href={href}>
        {children}
      </a>
    )
  }

  return (
    <Link css={{ ...defaultButtonStyles, ...styleOverrides }} to={href}>
      {children}
    </Link>
  )
}
