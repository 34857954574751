import React from 'react'
import generateContext from 'react-generate-context'
import { useSupabaseContext } from './SupabaseContext'

const useFeedbackValue = () => {
  const supabase = useSupabaseContext()
  const api = React.useMemo(
    () => ({
      createFeedback: async ({
        course_id,
        publicEndorsementPermission,
        rating,
        statement,
        user_id,
      }) => {
        if (!supabase || !course_id || !user_id) {
          throw new Error("Can't create feedback without a user or course")
        }

        const { data, error } = await supabase.from('feedback').insert({
          course_id,
          publicEndorsementPermission,
          rating,
          statement,
          user_id,
        })

        return { data, error }
      },
    }),
    [supabase]
  )

  return api
}

const [FeedbackProvider, useFeedbackContext] = generateContext(useFeedbackValue)

export { FeedbackProvider, useFeedbackContext }
