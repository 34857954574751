import React from 'react'
import generateContext from 'react-generate-context'
import { useSupabaseContext } from './SupabaseContext'

const useProductsValue = () => {
  const supabase = useSupabaseContext()
  const [products, setProducts] = React.useState([])

  React.useEffect(() => {
    if (!supabase) return

    async function getProducts() {
      const { data, error } = await supabase.from('products').select()

      if (error) {
        // do something
      }

      if (data) {
        setProducts(data)
      }
    }

    getProducts()
  }, [supabase])

  return products
}

const [ProductsProvider, useProductsContext] = generateContext(useProductsValue)
ProductsProvider.displayName = 'ProductsProvider'

export { ProductsProvider, useProductsContext }
