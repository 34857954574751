import React from 'react'
import { SpacingProvider } from '@kyleshevlin/layout'
import { AuthProvider } from './contexts/AuthContext'
import { FeedbackProvider } from './contexts/FeedbackContext'
import { ProductsProvider } from './contexts/ProductsContext'
import { PurchaseProvider } from './contexts/PurchasesContext'
import { SupabaseProvider } from './contexts/SupabaseContext'
import { bs } from './shevy'

const spacing = x => {
  if (typeof x === 'number') return bs(x)

  return x
}

export default function CustomRootElement({ element }) {
  return (
    <SupabaseProvider>
      <AuthProvider>
        <ProductsProvider>
          <PurchaseProvider>
            <FeedbackProvider>
              <SpacingProvider spacing={spacing}>{element}</SpacingProvider>
            </FeedbackProvider>
          </PurchaseProvider>
        </ProductsProvider>
      </AuthProvider>
    </SupabaseProvider>
  )
}
