import React from 'react'
import { Link } from 'gatsby'
import { bs } from '../shevy'
import { useAuthContext } from '../contexts/AuthContext'
import MaxWidth from './MaxWidth'

const linkStyles = {
  display: 'inline-block',
  fontFamily: 'var(--fonts-catamaran)',
  color: 'var(--colors-text)',
  padding: bs(0.5),
}

function NavLink({ text, to }) {
  return (
    <Link
      css={linkStyles}
      activeStyle={{ color: 'var(--colors-accent)' }}
      title={text}
      to={to}
    >
      {text}
    </Link>
  )
}

export default function Nav() {
  const [authState] = useAuthContext()

  return (
    <div
      css={{
        backgroundColor: 'var(--components-nav-background)',
      }}
    >
      <MaxWidth>
        <nav
          css={{
            paddingLeft: bs(),
            paddingRight: bs(),
            marginLeft: bs(-0.5),
          }}
        >
          <NavLink text="Home" to="/" />
          <NavLink text="Courses" to="/courses" />
          {authState === 'AUTHENTICATED' && (
            <NavLink text="Learn" to="/learn" />
          )}
        </nav>
      </MaxWidth>
    </div>
  )
}
