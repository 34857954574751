import React from 'react'
import Footer from './Footer'
import Header from './Header'
import AllGlobalStyles from '../styles/AllGlobalStyles'
import Flex from './Flex'
import DetectResetPassword from './DetectResetPassword'
import Spacer from './Spacer'

export default function Layout({ children }) {
  return (
    <DetectResetPassword>
      <AllGlobalStyles />
      <Flex direction="column" styles={{ minHeight: '100vh' }}>
        <Header />
        <div css={{ flexGrow: 1 }}>
          <main role="main">{children}</main>
        </div>
        <Spacer top={4}>
          <Footer />
        </Spacer>
      </Flex>
    </DetectResetPassword>
  )
}
