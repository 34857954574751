import { navigate } from 'gatsby-link'
import React from 'react'

/**
 * The purpose of this function is to detect a hash including type=recovery
 * This is how Supabase attempts to initiate a reset password workflow
 * If we don't detect and respond to this, well, nothing happens
 */
export default function DetectResetPassword({ children }) {
  const hash = typeof window !== 'undefined' ? window.location.hash : null

  if (TYPE_RECOVERY_PATTERN.test(hash)) {
    const accessToken = getAccessTokenFromHash(hash)

    if (accessToken) {
      navigate(`/reset-password?access_token=${accessToken}`)
    }
  }

  return <>{children}</>
}

const TYPE_RECOVERY_PATTERN = /type=recovery/
const ACCESS_TOKEN_PATTERN = /access_token=(?<accessToken>.*)&refresh_token/

const getAccessTokenFromHash = hash => {
  const match = hash.match(ACCESS_TOKEN_PATTERN)
  return match?.groups?.accessToken
}
