import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { bs } from '../shevy'
import Button from './Button'
import Nav from './Nav'
import { useTheme } from './ThemeProvider'
import { mq } from '../utils'
import { useAuthContext } from '../contexts/AuthContext'
import LinkButton from './LinkButton'
import ShiftBy from './ShiftBy'
import Spacer from './Spacer'
import Sun from './icons/Sun'
import Moon from './icons/Moon'
import MaxWidth from './MaxWidth'

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        subTitle
      }
    }
  }
`

export default function Header() {
  const data = useStaticQuery(query)
  const { subTitle, title } = data.site.siteMetadata

  return (
    <header>
      <div
        css={{
          backgroundColor: 'var(--components-header-background)',
          color: 'var(--components-header-text)',
        }}
      >
        <MaxWidth>
          <div
            css={{
              paddingLeft: bs(),
              paddingRight: bs(),
              display: 'flex',
              flexDirection: 'column',

              [mq.alpha]: {
                flexDirection: 'row',
                alignItems: 'center',
              },
            }}
          >
            <ShiftBy y={-3}>
              <Link
                css={{
                  color: 'var(--components-header-text)',
                  display: 'block',
                  paddingTop: bs(0.25),
                  paddingBottom: bs(0.25),
                }}
                to="/"
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'baseline',
                    flexWrap: 'wrap',
                  }}
                >
                  <Spacer right={0.25}>
                    <h1
                      css={{
                        lineHeight: 1,
                        marginBottom: 0,
                      }}
                    >
                      {title}
                    </h1>
                  </Spacer>

                  <div
                    css={{
                      fontFamily: 'var(--fonts-catamaran)',
                      fontSize: '1rem',
                      marginBottom: 0,
                    }}
                  >
                    {subTitle}
                  </div>
                </div>
              </Link>
            </ShiftBy>
            <div
              css={{
                alignItems: 'center',
                display: 'flex',
                fontFamily: 'var(--fonts-catamaran)',
                gap: bs(0.75),
                marginTop: bs(0.5),
                marginBottom: bs(0.5),

                [mq.alpha]: {
                  marginTop: 0,
                  marginBottom: 0,
                  marginLeft: 'auto',
                },
              }}
            >
              <Actions />
              <ThemeRotator />
            </div>
          </div>
        </MaxWidth>
      </div>
      <Nav />
    </header>
  )
}

function Actions() {
  const [authState, { logout, session }] = useAuthContext()

  switch (authState) {
    case 'AUTHENTICATED':
      return (
        <>
          {Boolean(session) && (
            <Link to="/profile" title="Profile">
              <div
                css={{
                  backgroundColor: 'var(--components-avatar-background)',
                  color: 'var(--components-avatar-text)',
                  width: 28,
                  height: 28,
                  borderRadius: '50%',
                  textAlign: 'center',
                }}
              >
                <ShiftBy y={4.5}>
                  <div css={{ lineHeight: 1 }}>
                    {session.user.email[0].toUpperCase()}
                  </div>
                </ShiftBy>
              </div>
            </Link>
          )}
          <Button
            onClick={logout}
            styleOverrides={{ color: 'inherit', lineHeight: 1 }}
            variant="link"
          >
            Log out
          </Button>
        </>
      )

    case 'NOT_AUTHENTICATED':
    default:
      return (
        <>
          <Link css={{ color: 'inherit' }} to="/login">
            Log In
          </Link>
          <LinkButton
            href="/signup"
            styleOverrides={{
              backgroundColor: 'var(--colors-contra)',
              '&:hover': {
                backgroundColor: 'var(--colors-contraLight)',
                color: 'inherit',
              },
            }}
          >
            Sign Up
          </LinkButton>
        </>
      )
  }
}

function ThemeRotator() {
  const { rotateTheme, theme } = useTheme()
  const icon = getIcon(theme)

  return (
    <Button
      aria-label="Toggle theme"
      onClick={rotateTheme}
      title="Toggle theme"
    >
      <ShiftBy y={1}>{icon}</ShiftBy>
    </Button>
  )
}

function getIcon(theme) {
  switch (theme) {
    case 'light':
      return <Moon width={14} />

    case 'dark':
      return <Sun width={14} />
  }
}
