import React from 'react'
import getSupabaseInstance from '../utils/getSupabaseInstance'

const SupabaseContext = React.createContext(null)

export function SupabaseProvider({ children }) {
  const [supabase, setSupabase] = React.useState(null)

  React.useEffect(() => {
    async function getSupabase() {
      const supabaseModule = await import('@supabase/supabase-js')
      const supabase = getSupabaseInstance(supabaseModule)
      setSupabase(supabase)
    }

    getSupabase()
  }, [])

  return (
    <SupabaseContext.Provider value={supabase}>
      {children}
    </SupabaseContext.Provider>
  )
}

export const useSupabaseContext = () => React.useContext(SupabaseContext)
