export const BREAKPOINTS = {
  alpha: '481px',
  bravo: '769px',
  charlie: '1025px',
  delta: '1350px',
}

export const FONTS = {
  catamaran: 'Catamaran, sans-serif',
}
