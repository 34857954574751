import Gif from "../../../../src/components/Gif";
import Spacer from "../../../../src/components/Spacer";
import FoldItIn from "../../../../src/courses/array-reduce/images/foldItIn.gif";
import FoldItInStatic from "../../../../src/courses/array-reduce/images/foldItInStatic.jpg";
import * as React from 'react';
export default {
  Gif,
  Spacer,
  FoldItIn,
  FoldItInStatic,
  React
};