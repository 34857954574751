const databaseUrl = process.env.GATSBY_SUPABASE_DATABASE_URL
const publicKey = process.env.GATSBY_SUPABASE_ANON_PUBLIC_KEY

let supabaseInstance
export default function getSupabaseInstance(supabaseModule) {
  if (supabaseInstance) return supabaseInstance

  const supabase = supabaseModule.createClient(databaseUrl, publicKey)
  supabaseInstance = supabase

  return supabase
}
