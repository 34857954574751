import React from 'react'

export default function MaxWidth({ children }) {
  return (
    <div
      css={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 1800,
      }}
    >
      {children}
    </div>
  )
}
