import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Flex from './Flex'
import { bs } from '../shevy'
import Github from './icons/Github'
import Twitter from './icons/Twitter'
import { Link } from 'gatsby'
import MaxWidth from './MaxWidth'

const internalLinks = [{ href: '/faq', text: 'FAQ' }]

const iconLinks = [
  { href: 'https://twitter.com/kyleshevlin', icon: Twitter, title: 'Twitter' },
  { href: 'https://github.com/kyleshevlin', icon: Github, title: 'Github' },
]

export default function Footer() {
  return (
    <div
      css={{
        backgroundColor: 'var(--components-footer-background)',
        color: 'var(--components-footer-text)',
        fontFamily: 'var(--fonts-catamaran)',
        padding: `${bs(0.5)} ${bs()}`,
        textAlign: 'center',
      }}
    >
      <MaxWidth>
        <Flex justify="space-between" align="center" gap={bs()}>
          <Flex>
            {internalLinks.map(({ href, text }) => (
              <Link key={href} to={href}>
                {text}
              </Link>
            ))}
          </Flex>

          <div>
            &copy;{new Date().getFullYear()} Courses by Kyle Shevlin. All Rights
            Reserved.
          </div>
          <Flex align="center" gap={bs()}>
            {iconLinks.map(({ href, icon: Icon, title }) => (
              <OutboundLink
                css={{
                  display: 'inline-block',
                  '&:hover svg': {
                    fill: 'var(--components-button-hover-background)',
                  },
                  svg: {
                    transition: 'fill 0.3s ease',
                  },
                }}
                key={title}
                href={href}
                title={title}
              >
                <Flex justify="center" align="center">
                  <Icon
                    fill={'var(--components-button-background)'}
                    width={30}
                  />
                </Flex>
              </OutboundLink>
            ))}
          </Flex>
        </Flex>
      </MaxWidth>
    </div>
  )
}
