import React from 'react'
import Layout from './components/Layout'
import OffsetWrap from './components/OffsetWrap'
import Spacer from './components/Spacer'
import Vimeo from './components/Vimeo'
import { ThemeProvider } from './components/ThemeProvider'
import { MDXProvider } from '@mdx-js/react'
import { makeHeadingId } from './utils'

const makeHeading = element => ({ children, ...props }) => {
  const el = React.createElement(
    element,
    { id: makeHeadingId(children), ...props },
    children
  )

  try {
    if (Object.isExtensible(el)) {
      el.displayName = element
    }
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err)
    }
  }

  return el
}

const H2 = makeHeading('h2')
const H3 = makeHeading('h3')
const H4 = makeHeading('h4')
const H5 = makeHeading('h5')
const H6 = makeHeading('h6')

const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <MDXProvider
        components={{
          h2: H2,
          h3: H3,
          h4: H4,
          h5: H5,
          h6: H6,
          OffsetWrap,
          Spacer,
          Vimeo,
        }}
      >
        <Layout {...props}>{element}</Layout>
      </MDXProvider>
    </ThemeProvider>
  )
}

export default wrapPageElement
